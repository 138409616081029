function fetchAPI( url, options = {}) {
  const defaultOpts = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const mergedHeaders = { ...defaultOpts.headers, ...options.headers };

  const requestOptions = { ...defaultOpts, ...options, headers: { ...mergedHeaders } };

  return fetch( url, requestOptions )
    .then(async (response) => {
      // actually, we're supposed to check the response's content-type
      // instead of try-catching a JSON.parse. But it's not like we're
      // doing a fully-fledged production app...
      try {
        return response.json();
      } catch (err) {
        return response.body();
      }
    });
}

class App {
  constructor() {
    this.rankedArticles = [];
    this.profile = null;
    this.boundArticleTransitionEnd = this.onArticleTransitionEnd.bind(this);

    this.getLocalUser()
      .then(this.updateLocalUsername)
      .then(this.populateArticles.bind(this))
      .then(this.setupProfileWheel.bind(this))
      .catch(this.createNewUser);
  }

  updateLocalUsername(user) {
    [ ...document.querySelectorAll('h1 span') ].forEach(el => {
      el.textContent = `${user.name}'${user.name.match(/s$/) ? '' : 's'}`;
    });
    return user;
  }

  getLocalUser() {
    return new Promise((resolve, reject) => {
      const user = this.getLocalProfile();

      if (!user || !user.userId) {
        reject();
      }

      resolve(user);
    });
  }

  getLocalProfile() {
    return JSON.parse(localStorage.getItem('profile'));
  }

  clearUser() {
    localStorage.removeItem('profile');
    this.createNewUser();
  }

  getUserArticles(user) {
    const localUser = user || this.getLocalProfile;
    return fetchAPI(`/api/users/${user.userId}/articles`);
  }

  getOnlineProfile(user) {
    return fetchAPI( `/api/users/${user.userId}`);
  }

  async setupProfileWheel(user) {
    const { profile } = await this.getOnlineProfile(user);
    const mainInterests = profile.sort(this.sortDescOnKey('weight')).slice(0, 4);
    const interestSpans = [ ...document.body.querySelectorAll('.wheel-container span') ];
    const maxWeight = Math.max.apply(null, mainInterests.map(int => int.weight));

    let pathD = '';

    let lastX = 0;
    let lastY = 0;
    mainInterests.forEach((interest, i) => {
      interestSpans[i].textContent = interest.category;
      const weight = interest.weight / maxWeight * 100 * 0.3;
      const weightX = 50 + weight * (i % 3 ? 1 : -1);
      const weightY = 50 + weight * (i > 1 ? 1 : -1);

      pathD += ` ${i ? 'L' : 'M'} ${weightX} ${weightY}`

      if (!i) {
        lastX = weightX;
        lastY = weightY;
      }
    });
    pathD += ` L ${lastX} ${lastY}`

    document.getElementById('profile').setAttribute('d', pathD);
  }

  createNewUser(err) {
    window.location.href = '/user/new';
  }

  populateArticles(user) {
    this.getUserArticles(user)
      .then(({ articles }) =>
        articles
          .sort(this.sortDescOnKey('score'))
          .forEach(this.displayArticle.bind(this))
      );

    setTimeout(() => {
      let el = document.querySelector('ul');
      el.style.minHeight = el.clientHeight + 'px';
    }, 1000);
    return user;
  }

  displayArticle(article) {
    const articleItem = document.createElement('li');
    const articleName = document.createElement('span');
    articleName.textContent = article.name;
    articleItem.appendChild(articleName);
    articleItem.style.background = `url(${article.imageUrl}) center / cover`;
    articleItem.setAttribute('data-articleid', article.id);
    document.querySelector('ul').appendChild(articleItem);
    articleItem.addEventListener('click', this.showInterest.bind(this), false);
  }

  sortDescOnKey(key) {
    return (a, b) => b[key] - a[key];
  }

  showInterest(e) {
    const user = this.getLocalProfile();
    const articleId = e.currentTarget.getAttribute('data-articleid');

    this.updateMyInterestsWith(user, articleId)
      .then(() => this.getUserArticles(user))
      .then(this.reOrderArticles.bind(this));
  }

  updateMyInterestsWith({ userId }, articleId) {
    return fetchAPI(`/api/users/${userId}/articles/${articleId}/interest`, {
      method: 'POST'
    });
  }

  reOrderArticles({ articles }) {
    const articleContainer = document.querySelector('ul.article-container');
    const currentElements = [ ...articleContainer.querySelectorAll('li') ];
    const currentPositions = [];

    currentElements.forEach((el, i) => {
      const { offsetLeft, offsetTop } = el;
      el.style.top = offsetTop + 'px';
      el.style.left = offsetLeft + 'px';
      currentPositions.push({
        x: offsetLeft,
        y: offsetTop,
        articleId: +el.getAttribute('data-articleid')
      });
    });

    articleContainer.classList.add('reordering');

    let lastTransitionedElement = null;
    setTimeout(() => {
      this.rankedArticles =
        articles
          .sort(this.sortDescOnKey('score'))
          .map(article => ({
              ...article,
              element: articleContainer.querySelector(`li[data-articleid="${article.id}"]`)
            })
          );

      this.rankedArticles
        .forEach((article, i) => {
          article.element.style.top = `${currentPositions[i].y}px`;
          article.element.style.left = `${currentPositions[i].x}px`;

          if ( article.id !== currentPositions[i].articleId ) {
            lastTransitionedElement = article.element;
          }
        });

      if (lastTransitionedElement) {
        lastTransitionedElement.addEventListener('transitionend', this.boundArticleTransitionEnd, false);
      }
      else {
        currentElements.forEach(el => {
          el.style.top = '';
          el.style.left = '';
        });
        articleContainer.classList.remove('reordering');
      }
    }, 0);
  }

  onArticleTransitionEnd(evt) {
    const articleContainer = document.querySelector('ul.article-container');

    if (evt.propertyName === 'left') {
      evt.currentTarget.removeEventListener('transitionend', this.boundArticleTransitionEnd, false);

      [ ...articleContainer.childNodes ]
        .forEach(element => {
          if (element instanceof Text) {
            return;
          }

          element.style.top = '';
          element.style.left = '';
          articleContainer.removeChild(element);
        });

      this.rankedArticles
        .forEach((article, i) => {
          articleContainer.appendChild(article.element);
        });

      articleContainer.classList.remove('reordering');
    }
  }
}

const app = new App();
